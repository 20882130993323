import React, { useEffect } from "react";
import { ImageConstants } from "../constants/Imagconstants";
import {
  homeSlider,
  homeSkillSlider,
  workSlider,
} from "../constants/swiperConstants";
import { Swiper, SwiperSlide } from "swiper/react";
import Layout from "./Layout";
import { Link } from "gatsby";
import { routes } from "../constants/routes";

const Home = () => {
  return (
    <Layout
      title={"Consumer Web & Mobile application company | Luxembourg"}
      description={
        "RazrCorp is a consumer web & mobile product company that specialises in services like Ui/UX designing, outsourcing, core software development, digital marketing across UK, Europe, and Asia."
      }
      keywords={"Outsource developers in Europe and UK, Ui/Ux designing"}
      url={"https://www.razrcorp.com/"}
    >
      <main>
        <section className="homeSliderSection">
          <Swiper pagination={true} className="homeslider" {...homeSlider}>
            <div className="swiper-wrapper">
              {/* <SwiperSlide>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div className="homeslider-box">
                        <h1 className="animate__animated animate__slideInLeft">
                          Speed up your <br /> development <br />
                          with RazrCorp.
                        </h1>
                        <p className="animate__animated animate__slideInLeft">
                          Your own IT services company team from hiring high
                          performing development teams to fully managed
                          services, we’re your partner to give you the very best
                          support for all your technology needs.
                        </p>
                        <div className="slider-btn">
                          <a
                            href="https://form.typeform.com/to/XTDkRIPl"
                            target="_blank"
                            className="btn"
                          >
                            Contact us{" "}
                          </a>
                          <a
                            href="https://calendly.com/razrco"
                            target="_blank"
                            className="btn"
                          >
                            Book an appointment
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="slideanimation">
                        <video autoPlay={true} muted>
                          <source
                            src={ImageConstants.finalVideoWebM}
                            type="video/webm"
                          />
                          <source
                            src={ImageConstants.finalVideoMp4}
                            type="video/mp4"
                          />
                          Your browser does not support HTML video.
                        </video>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide> */}
              <SwiperSlide>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div className="homeslider-box">
                        <h1>
                          Innovate like a neo-bank; Effortless modernisation
                        </h1>

                        <p>
                          Modernise your frontend with 100's of mapped flows &
                          world-class ux compatible with leading core banking
                          providers
                        </p>

                        <div className="slider-btn">
                          {/* <a
                            href="https://www.thunderboltjs.com/"
                            target="_blank"
                            className="btn"
                          >
                            Know more{" "}
                          </a> */}
                          <a
                            href="https://calendly.com/razrco"
                            target="_blank"
                            className="btn"
                          >
                            Book an appointment
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="slideanimation">
                        <figure>
                          <img
                            src={ImageConstants.homeSlider1}
                            alt="effective"
                            width="auto"
                            height="auto"
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div className="homeslider-box">
                        <h1>Build a high performance Tech Team</h1>
                        <p>
                          Your own team in the cloud. Work with the best
                          designers, developers & devOps specialists to bring
                          your project to life. No big bills, No templates,
                          Simple & Secure, hassle free billing. All specialists
                          in-house.
                        </p>
                        <div className="slider-btn">
                          <a
                            href="https://form.jotform.com/222352612041340"
                            target="_blank"
                            className="btn"
                          >
                            Contact us{" "}
                          </a>
                          <a
                            href="https://calendly.com/razrco"
                            target="_blank"
                            className="btn"
                          >
                            Book an appointment
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="slideanimation second-slide">
                        <figure>
                          <img
                            src={ImageConstants.homeSlider2}
                            alt="effective"
                            width="auto"
                            height="auto"
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div className="homeslider-box">
                        <h1>Let’s make IT happen</h1>
                        <p>
                          We are a consumer web & mobile application company
                          with HQ in Luxembourg.
                        </p>
                        <div className="slider-btn">
                          <a
                            href="https://form.jotform.com/222352612041340"
                            target="_blank"
                            className="btn"
                          >
                            Contact us{" "}
                          </a>
                          <a
                            href="https://calendly.com/razrco"
                            target="_blank"
                            className="btn"
                          >
                            Book an appointment
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="slideanimation third-slide">
                        <figure>
                          <img
                            src={ImageConstants.homeSlider3}
                            alt="effective"
                            width="auto"
                            height="auto"
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </div>
            {/* <div className="swiper-pagination"></div> */}
            {/* <div className="swiper-button-next">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24.377"
              height="46.617"
              viewBox="0 0 24.377 46.617"
            >
              <path
                id="Path_97213"
                data-name="Path 97213"
                d="M232.75,1014.467l17.135,20.968L232.75,1052.62"
                transform="translate(-228.507 -1010.246)"
                fill="none"
                stroke="#1543A2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="6"
              />
            </svg>
          </div> */}
            {/* <div className="swiper-button-prev">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24.377"
              height="46.617"
              viewBox="0 0 24.377 46.617"
            >
              <path
                id="Path_97212"
                data-name="Path 97212"
                d="M249.884,1014.467l-17.135,20.968,17.135,17.185"
                transform="translate(-229.75 -1010.246)"
                fill="none"
                stroke="#1543A2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="6"
              />
            </svg>
          </div> */}
          </Swiper>
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="client-logo">
                  <figure>
                    <img
                      loading="lazy"
                      src={ImageConstants.vodafoneLogo}
                      alt="vodafone"
                      width="auto"
                      height="auto"
                    />
                  </figure>
                  <figure>
                    <img
                      loading="lazy"
                      src={ImageConstants.paylogo}
                      alt="pay"
                      width="auto"
                      height="auto"
                    />
                  </figure>
                  <figure>
                    <img
                      loading="lazy"
                      src={ImageConstants.tmLogo}
                      alt="tm"
                      width="150"
                      height="150"
                    />
                  </figure>
                  <figure>
                    <img
                      loading="lazy"
                      src={ImageConstants.audiLogo}
                      alt="audi"
                      width="auto"
                      height="auto"
                    />
                  </figure>
                  <figure>
                    <img
                      loading="lazy"
                      src={ImageConstants.geLogo}
                      alt="GE"
                      width="auto"
                      height="auto"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div>
                  <h2 className="commantitel">50+ Successfully completed</h2>
                  <h2 className="commantitel">projects & ideas</h2>
                  <p className="commanp mt-3">
                    We believe in empowering businesses and solving our client’s
                    toughest{" "}
                  </p>
                  <p className="commanp">
                    challenges through digital innovation and disruptions.
                  </p>
                </div>
              </div>
            </div>
            <div className="what-bg mt-5">
              <div className="row  mobileflexreverse">
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12">
                  <div className="what-we-do-main">
                    <h2 className="commantitel">What we do</h2>
                    <p className="commanp">
                      We are a consumer web & mobile product company. We
                      specialize in building consumer products which operate on
                      a large scale. We believe in empowering businesses and
                      solving our clients’ toughest challenges through digital
                      innovation and disruptions. We help our partners through
                      data and insight driven solutions, knowing that we’ve got
                      what it takes to bring their vision to life.
                    </p>

                    <Link className="btn btn-primary mt-5" to={routes.aboutUs}>
                      {" "}
                      Know more
                    </Link>
                  </div>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12">
                  <div className="what-we-do">
                    <figure>
                      <img
                        loading="lazy"
                        src={ImageConstants.whatWeDoIcon}
                        alt="what we do"
                        width="auto"
                        height="auto"
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="commantitel">Our Services</h2>
              </div>
            </div>
          </div>
          <Swiper className="workslider" {...workSlider}>
            <div className="swiper-wrapper">
              <SwiperSlide>
                <div className="work-main work-main-yellow border-radius-left-top">
                  <h3>
                    Design <br /> Services
                  </h3>
                  <p>
                    Not only a good looking interface, we are all about ensuring
                    the best experience. With data available across
                    applications, our design team works to provide an enhanced
                    experience on your product.
                  </p>
                  <Link
                    className="btn btn-primary mt-2"
                    to={routes.designServices}
                  >
                    Read more
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="work-main work-main-pink">
                  <h3>
                    Hire <br /> Developers
                  </h3>
                  <p>
                    Don’t just outsource, HIRE! We offer IT experts specializing
                    in technical stacks right from React JS to Node JS.
                  </p>
                  <Link
                    className="btn btn-primary mt-2"
                    to={routes.hireDevelopers}
                  >
                    Read more
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="work-main work-main-sky">
                  <h3>Development</h3>
                  <p>
                    Right from running a design studio apt in material
                    guidelines to terraforming DevOps, we provide some of the
                    very best talents for your IT needs.
                  </p>
                  <Link
                    className="btn btn-primary mt-2"
                    to={routes.outsourcedManagedServices}
                  >
                    Read more
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="work-main work-main-ppt border-radius-right-bottom">
                  <h3>
                    Support & <br /> Maintenance <br />
                    Services
                  </h3>
                  <p>
                    With our global presence, you get on-site project managers
                    and build an efficient workforce remotely. A solution-driven
                    team that loves challenges and drives growth.
                  </p>
                  <Link
                    className="btn btn-primary mt-2"
                    to={routes.supportMaintainanceServices}
                  >
                    Read more
                  </Link>
                </div>
              </SwiperSlide>
            </div>
            <div className="swiper-button-next">
              <img loading="lazy" src={ImageConstants.nextArrow} alt="next" />
            </div>
            <div className="swiper-button-prev">
              <img loading="lazy" src={ImageConstants.preArrow} alt="Prev" />
            </div>
          </Swiper>
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12 mb-5">
                <div className="graph">
                  <h2 className="commantitel">
                    Transforming Business across <br /> Europe and UK
                  </h2>
                  <p className="commanp mt-3">
                    We are proud on our top notch services. Our services comes
                    with following benefits:
                  </p>
                  <figure className="mt-5">
                    <img
                      loading="lazy"
                      src={ImageConstants.graphIcon}
                      alt="graph"
                      width="auto"
                      height="auto"
                    />
                  </figure>
                </div>
              </div>
            </div>
            <div className="row justify-content-center mobilescolldiv">
              <div className="col-lg-6 col-md-12">
                <div className="razrfeaturebox">
                  <figure>
                    <img
                      loading="lazy"
                      src={ImageConstants.fEffectiveicon}
                      alt="effective"
                      width="auto"
                      height="auto"
                    />
                  </figure>

                  <h5>Cost Effective Support</h5>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="razrfeaturebox">
                  <figure>
                    <img
                      loading="lazy"
                      src={ImageConstants.fResponsive}
                      alt="effective"
                      width="auto"
                      height="auto"
                    />
                  </figure>

                  <h5>Fast Response Times</h5>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="razrfeaturebox">
                  <figure>
                    <img
                      loading="lazy"
                      src={ImageConstants.fExpertise}
                      alt="effective"
                      width="auto"
                      height="auto"
                    />
                  </figure>

                  <h5>Specialist Expertise</h5>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="razrfeaturebox">
                  <figure>
                    <img
                      loading="lazy"
                      src={ImageConstants.fStrategic}
                      alt="effective"
                      width="auto"
                      height="auto"
                    />
                  </figure>

                  <h5>Strategic approach</h5>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="razrfeaturebox">
                  <figure>
                    <img
                      loading="lazy"
                      src={ImageConstants.fSecurity}
                      alt="effective"
                      width="auto"
                      height="auto"
                    />
                  </figure>

                  <h5>
                    Compliance & Security <br /> assurance
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="multiplatform">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="text-center">
                  <h2 className="commantitel">
                    Multiplatform Solutions For Digital <br /> Business
                    Transformation
                  </h2>
                </div>

                <div className="multip-box">
                  <div className="bx">
                    <figure>
                      <img
                        loading="lazy"
                        src={ImageConstants.iPhoneIcon}
                        alt="iphone"
                        width="auto"
                        height="auto"
                      />
                    </figure>
                    <figcaption>iOS</figcaption>
                  </div>
                  <div className="bx">
                    <figure>
                      <img
                        loading="lazy"
                        src={ImageConstants.androidIcon}
                        alt="androidmobile"
                        width="auto"
                        height="auto"
                      />
                    </figure>
                    <figcaption>Android</figcaption>
                  </div>
                  <div className="bx">
                    <figure>
                      <img
                        loading="lazy"
                        src={ImageConstants.laptopIcon}
                        alt="laptop"
                        width="auto"
                        height="auto"
                      />
                    </figure>
                    <figcaption>Web</figcaption>
                  </div>
                  <div className="bx">
                    <figure>
                      <img
                        loading="lazy"
                        src={ImageConstants.wearablesIcon}
                        alt="wearables"
                        width="auto"
                        height="auto"
                      />
                    </figure>
                    <figcaption>Wearables</figcaption>
                  </div>
                  <div className="bx">
                    <figure>
                      <img
                        loading="lazy"
                        src={ImageConstants.alexaIcon}
                        alt="alexa"
                        width="auto"
                        height="auto"
                      />
                    </figure>
                    <figcaption>IOT</figcaption>
                  </div>
                  <div className="bx">
                    <figure>
                      <img
                        loading="lazy"
                        src={ImageConstants.remoteIcon}
                        alt="alexa"
                        width="auto"
                        height="auto"
                      />
                    </figure>
                    <figcaption>TV</figcaption>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="skill-home">
                  <h2 className="commantitel">Hiring for a specific skill?</h2>
                </div>
              </div>
            </div>
          </div>
          <Swiper className="homeskillslider" {...homeSkillSlider}>
            <div className="swiper-wrapper">
              <SwiperSlide>
                <div>
                  <img
                    loading="lazy"
                    src={ImageConstants.rubbyLogo}
                    alt="rubby"
                    width="auto"
                    height="auto"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img
                    loading="lazy"
                    src={ImageConstants.pythonLogo}
                    alt="paython"
                    width="auto"
                    height="auto"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img
                    loading="lazy"
                    src={ImageConstants.phpLogo}
                    alt="php"
                    width="auto"
                    height="auto"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img
                    loading="lazy"
                    src={ImageConstants.reactLogo}
                    alt="react"
                    width="auto"
                    height="auto"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img
                    loading="lazy"
                    src={ImageConstants.nodejsLogo}
                    alt="node"
                    width="auto"
                    height="auto"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img
                    loading="lazy"
                    src={ImageConstants.IosLogo}
                    alt="ios"
                    width="auto"
                    height="auto"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img
                    loading="lazy"
                    src={ImageConstants.javaLogo}
                    alt="java"
                    width="auto"
                    height="auto"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img
                    loading="lazy"
                    src={ImageConstants.angularLogo}
                    alt="angular"
                    width="auto"
                    height="auto"
                  />
                </div>
              </SwiperSlide>
            </div>
          </Swiper>
        </section>
        <section>
          <div className="container">
            <div className="letstalksection">
              <div className="row">
                <div className="col-md-8">
                  <div className="text-center">
                    <h2 className="commantitel">Let’s make IT happen</h2>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="text-center">
                    <a
                      href="https://form.jotform.com/222352612041340"
                      target="_blank"
                      className="btn btn-primary"
                    >
                      Get in touch
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="map">
          {/* <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3772.0939100551127!2d73.0370373149002!3d19.015583087122604!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c25339420e09%3A0xf1eaf4e4fdf801d1!2sRazrcorp%20%3ANew%20age%20tech%20specialists%2C%20Mobile%20%26%20application%20development!5e0!3m2!1sen!2sin!4v1657176246263!5m2!1sen!2sin"
            width="600"
            height="450"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe> */}
        </div>
      </main>
    </Layout>
  );
};

export default Home;
